const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://james.cuadra.uk',
  title: 'JC.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'James Cuadra',
  role: 'Application Developer',
  description:
    'I am currently in my first year of a Degree Apprenticeship with Fujitsu UK working as an Application Developer and studying for a BSc (Hons) Digital and Technology Solutions at Manchester Metropolitan University. I currently work on Outsystems and AWS projects.',
  resume:
    'https://drive.google.com/file/d/14rQVKqR9VJ9K49l5S1d3A0eUoxbMjrUT/view?usp=sharing',
  social: {
    linkedin: 'https://www.linkedin.com/in/james-cuadra-49373317a/',
    github: 'https://github.com/James-Cuadra',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Planet Simulations',
    description:
      'A set of Python programs I wrote to simulate the orbits of planets, a 3D version is in the works using the Ursina Engine.',
    stack: ['Maths', 'Simulation', 'Python'],
    sourceCode: 'https://github.com/James-Cuadra/Planet-Simulation',
    livePreview:
      'https://github.com/James-Cuadra/Planet-Simulation/blob/main/First%20Moon.py',
  },
  {
    name: 'Rubix³',
    description:
      "A Rubik's Cube solving app developed for my A Level Computer Science Non-Examined Assesment (NEA) using camera recognition to input the current state of the cube.",
    stack: ['Javascript', 'Kotlin', 'Android'],
    sourceCode: 'https://github.com/James-Cuadra/Rubix3',
    livePreview: '',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Organised',
  'Punctual',
  'Independent',
  'Tutor',
  'Young Leader',
  'JavaScript',
  'Python',
  'NodeJS',
  'Arduino',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'james@cuadra.uk',
}

export { header, about, projects, skills, contact }
